import React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"

const TrialResult = () => (
  <Layout>
    <Seo title="無料トライアルお申し込み | 顔を出さないで感情の「見える化」をカンタンに。オンライン会議サポートツール | ワカチエ" />
    <div className="trialResult secondPageContens">
      <div className="content">
        <h1>無料トライアルお申し込み<br/>ありがとうございます</h1>
        <p>
          この度は無料トライアルをお申込みいただきありがとうございます。<br/>
          追って弊社営業担当よりご連絡申し上げます。
        </p>
      </div>
    </div>
  </Layout>
)

export default TrialResult
